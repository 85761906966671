<template>
  <div>
    <div class="has-text-right mb-2">
      <b-button type="is-primary" icon-left="plus" @click="addCategory">{{ $t('add') }} {{ $t('category') }}</b-button>
    </div>

    <div class="mb-4">
      <h4 class="is-size-5 has-text-weight-bold mb-2">Custom {{ $t('category') }}</h4>

      <finance-category-table :data="customExpensesCategories" can-edit @submit="loadData"/>
    </div>

    <div>
      <h4 class="is-size-5 has-text-weight-bold mb-2">Default {{ $t('category') }}</h4>

      <finance-category-table :data="expensesCategories"/>
    </div>

    <b-modal
        v-model="modal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <finance-category-form @close="props.close" type="out" @submit="loadData"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import FinanceCategoryForm from "@/components/Finance/FinanceCategoryForm";
import FinanceCategoryTable from "@/components/Finance/FinanceCategoryTable";

export default {
  name: "FinanceExpensesSetting",
  components: {FinanceCategoryTable, FinanceCategoryForm},
  data: () => {
    return {
      modal: false,
    }
  },
  computed: {
    ...mapGetters('finance', [
      'incomeCategories',
      'expensesCategories',
      'customIncomeCategories',
      'customExpensesCategories',
    ])
  },
  methods: {
    ...mapMutations('finance', [
      'UPDATE_SELECTED_CATEGORY'
    ]),

    ...mapActions('finance', [
      'getCustomTransactionCategory'
    ]),

    addCategory() {
      this.UPDATE_SELECTED_CATEGORY()
      this.modal = true
    },

    loadData() {
      this.$loading()
      this.getCustomTransactionCategory('out').finally(() => {
        this.$loading(false)
      })
    },
  },
}
</script>

<style scoped>

</style>